import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { Currency } from '@src/@types/currency';
import { getAmountString } from '@src/helpers/common';
import { CardApprovalProbability, getOfferRate, getCardTerm } from 'entities/Microcredits/ui/LightweightCard';

export const getCardContent = (
  { details, rates, approvalProbability }: IClientMicrocreditListItem,
  currency: Currency,
  isPhone: boolean,
) => [
  {
    value: getAmountString(details?.amount?.to || details?.amount?.from, currency),
    label: 'Сумма до',
    boldValue: true,
  },
  {
    value: getCardTerm(details?.term, isPhone),
    label: 'Срок (дни)',
  },

  {
    value: getOfferRate(rates),
    label: 'Ставка',
  },
  {
    value: <CardApprovalProbability category={approvalProbability?.category} />,
    label: 'Одобрение',
  },
];
