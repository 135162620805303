import { useRouter } from 'next/router';
import type { SyntheticEvent } from 'react';
import React from 'react';

import { Typography } from '@sravni/react-design-system';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import type { AffSub4 } from '@src/@types/microcredits';
import { useMediaQuery } from '@src/components/item/hooks';
import { Pixel } from '@src/components/Pixel';
import { useCardClick } from '@src/components/ProductList/components/Card/useCardClick';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { MONETIZATION_INTERNAL } from '@src/helpers/advertising';
import { sendAdvertisingButtonClickEvent } from '@src/helpers/analyticsEvents';
import { open } from '@src/helpers/navigationHelper';
import { usePixel } from '@src/hooks/usePixel';
import { LightweightCardView } from 'entities/Microcredits/ui';
import { getCardBadge } from 'entities/Microcredits/ui/LightweightCard';

import { getCardContent } from './LightweightCard.config';
import styles from './LightweightCard.module.scss';

interface Props {
  offer: IClientMicrocreditListItem;
  position: number;

  currency?: Currency;
  hasUtmLabel: boolean;
}

export const LightweightCard = ({ offer, currency = Currency.RUB, position, hasUtmLabel }: Props) => {
  const router = useRouter();
  const isPhone = useMediaQuery(0, 450);

  const { organization, advertising, name, _id } = offer;

  const { sourceLink, pixelDisplayLink } = useCardClick({ offer, position });

  // @todo: Добавить эту штуку если данная карточка добавится на страницу с listKey === ListKeys.WL_PAGE
  // а лучше передать это через пропс
  //   const isTemporaryTerm = isReviewer && listKey === ListKeys.WL_PAGE;

  // @todo: Добавить эту штуку если данная карточка добавится на страницу 2GIS
  // const sourceAffI = isPartner2Gis ? PARTNER_2GIS_SOURCE_HO_PARAM : `${sourceLink}|position_${position}`;

  const source = `${sourceLink}|position_${position}`;
  const affSub4: AffSub4 = {
    organizationName: organization.name,
    productName: name,
    organizationId: organization._id,
    productId: _id,
  };

  const content = getCardContent(offer, currency, isPhone);
  const badge = getCardBadge(offer, hasUtmLabel, styles);

  const mfoSiteLink = usePixel(advertising?.monetization?.pixels?.click, {
    sub: advertising.advSub,
    affSub4,
    type: advertising?.productType,
    source,
    pixelType: PIXELS_TYPES.CLICK,
  });

  const openMfoSite = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    sendAdvertisingButtonClickEvent(offer);

    if (!mfoSiteLink) {
      return;
    }

    if (advertising?.monetization?.kind === MONETIZATION_INTERNAL) {
      router.push(mfoSiteLink);
      return;
    }

    open({ href: mfoSiteLink, target: '_blank', features: 'noopener' });
  };

  return (
    <LightweightCardView
      offer={offer}
      content={content}
      badge={badge}
      actions={
        <Typography.Link className={styles.button} href={mfoSiteLink} onClick={openMfoSite} rel="nofollow">
          Получить деньги
        </Typography.Link>
      }
      pixelDisplay={<Pixel source={source} link={pixelDisplayLink} affSub4={affSub4} withWrapper={false} />}
      onCardClick={openMfoSite}
    />
  );
};
